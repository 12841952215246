import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  Navigate,
} from "react-router-dom";

import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import "./assets/css/main.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainLayout from "./layouts/MainLayout";
import { LoggedInRoute } from "./context/auth";
import LoadingScreen from "./pages/LoadingScreen";
import Alias from "./pages/Alias";
const VerifyUser = React.lazy(() => import("./pages/VerifyUser"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Users = React.lazy(() => import("./pages/Users"));
const CreateUser = React.lazy(() => import("./pages/CreateUser"));
const Groups = React.lazy(() => import("./pages/Groups"));
const CreateGroup = React.lazy(() => import("./pages/CreateGroup"));
const AddUserToGroups = React.lazy(() => import("./pages/AddUserToGroups"));
const AddUsersToGroup = React.lazy(() => import("./pages/AddUsersToGroup"));
const Policies = React.lazy(() => import("./pages/Policies"));
const CreatePolicy = React.lazy(() => import("./pages/CreatePolicy"));
const AddPolicyToGroups = React.lazy(() => import("./pages/AddPolicyToGroups"));
const ViewGroup = React.lazy(() => import("./pages/ViewGroup"));
const ViewUser = React.lazy(() => import("./pages/ViewUser"));
const ViewPolicy = React.lazy(() => import("./pages/ViewPolicy"));
const SSO = React.lazy(() => import("./pages/SSO"));
const SetupSSO = React.lazy(() => import("./pages/SetupSSO"));
const Profile = React.lazy(() => import("./pages/Profile"));

export default function Root(props) {
  return (
    <ChakraProvider theme={theme}>
      <Router basename="/iam">
        <React.Suspense fallback={<LoadingScreen />}>
          <Routes>
            <Route
              path="/"
              element={
                <LoggedInRoute>
                  <MainLayout>
                    <Outlet />
                  </MainLayout>
                </LoggedInRoute>
              }
            >
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route key="users/list" path="users/list" element={<Users />} />
              <Route
                key="users/:username"
                path="users/:username"
                element={<ViewUser />}
              />
              <Route
                key="users/new"
                path="users/new"
                element={<CreateUser />}
              />
              <Route
                key="users/addGroup/:username"
                path="users/addGroup/:username"
                element={<AddUserToGroups />}
              />
              <Route
                key="groups/:group_name"
                path="groups/:group_name"
                element={<ViewGroup />}
              />
              <Route
                key="groups/list"
                path="groups/list"
                element={<Groups />}
              />
              <Route
                key="groups/new"
                path="groups/new"
                element={<CreateGroup />}
              />
              <Route
                key="groups/addUser/:name"
                path="groups/addUser/:name"
                element={<AddUsersToGroup />}
              />
              <Route
                key="policies/list"
                path="policies/list"
                element={<Policies />}
              />
              <Route
                key="policies/:policy_name"
                path="policies/:policy_name"
                element={<ViewPolicy />}
              />
              <Route
                key="policies/new"
                path="policies/new"
                element={<CreatePolicy />}
              />
              <Route
                key="policies/addGroup/:name"
                path="policies/addGroup/:name"
                element={<AddPolicyToGroups />}
              />
              <Route key="sso" path="sso" element={<SSO />} />
              <Route key="sso/setup" path="sso/setup" element={<SetupSSO />} />
              <Route key="profile" path="profile" element={<Profile />} />
              <Route key="alias" path="alias" element={<Alias />} />
            </Route>
            <Route
              path="invite/:account_id/:username/:token"
              element={<VerifyUser />}
            />
          </Routes>
        </React.Suspense>
      </Router>
      <ToastContainer />
    </ChakraProvider>
  );
}
