export const unique = (value, index, self) => {
    return self.indexOf(value) === index
}


export const isPermissionError = (error) => {
    return error.includes('7 PERMISSION_DENIED')
}

export const parseAllowedPermissions = (permissions = []) => {
    let p = {};
    let allPermissions = []
    let services = [];
    Object.keys(permissions).map(key => {
      if(key != "*"){
        allPermissions.push(key + ":" + "*");
        permissions?.[key]?.allowed_permissions?.map(permission => allPermissions.push(key + ":" + permission))
        p[key] = permissions?.[key]?.allowed_permissions
        services.push(key)
      }
      else {
        p[key] = [key]
        allPermissions.push(key)
      }
      return key;
    });
    return {
        allPermissions: allPermissions.sort(),
        services: services,
        permissionMapByService: p
    };
}