// chakra imports
import { Box, Flex, Icon, useColorModeValue, Stack, useBreakpointValue } from "@chakra-ui/react";
//   Custom components
import Brand from "./Brand";
import React from "react";
import SidebarLink from "./Link";
import {
  FaChartBar,
  FaShareAltSquare,
  FaHistory,
  FaRegQuestionCircle,
  FaUserPlus,
} from "react-icons/fa";
import {
  RiAddCircleLine,
  RiShieldKeyholeFill,
  RiUserReceivedFill,
} from "react-icons/ri";
import { isLoggedIn } from "@defense-station/auth";
import { IoLogIn } from "react-icons/io5";
import { HiUsers } from "react-icons/hi";
import { MdGroups, MdPolicy } from "react-icons/md";
import CollapsableLink from "./CollapsableLink";
import { FiList } from "react-icons/fi";

// FUNCTIONS

function SidebarContent(props) {
  const textColor = useColorModeValue("navy.700", "white");
  const authenticated = isLoggedIn();
  const iconSize = useBreakpointValue({
    base: "20px",
    xl: "10px",
    "2xl": "20px",
  });
  // SIDEBAR
  return (
    <Flex
      direction="column"
      minH="100%"
      height="max-content"
      pt="15px"
      borderRadius="30px"
    >
      <Brand />
      <Stack direction="column" mb="auto" mt="8px">
        <Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
          {authenticated ? (
            <>
              <SidebarLink
                to="/dashboard"
                name="Dashboard"
                icon={
                  <Icon
                    as={FaChartBar}
                    width={iconSize}
                    height={iconSize}
                    color="inherit"
                  />
                }
              />
              <SidebarLink
                   to="users/list"
                   name="Users"
                   icon={
                     <Icon
                       as={HiUsers}
                       width={iconSize}
                       height={iconSize}
                       color="inherit"
                     />
                   }
                />
                <SidebarLink
                  to="groups/list"
                  name="Groups"
                  icon={
                    <Icon
                      as={MdGroups}
                      width={iconSize}
                      height={iconSize}
                      color="inherit"
                    />
                  }
                />
                <SidebarLink
                  to="policies/list"
                  name="Policies"
                  icon={
                    <Icon
                      as={MdPolicy}
                      width={iconSize}
                      height={iconSize}
                      color="inherit"
                    />
                  }
                />
              <SidebarLink
                to="/sso"
                name="SSO"
                icon={
                  <Icon
                    as={RiShieldKeyholeFill}
                    width={iconSize}
                    height={iconSize}
                    color="inherit"
                  />
                }
              />
            </>
          ) : (
            <>
              <SidebarLink
                as="button"
                to="/login"
                name="Login"
                icon={
                  <Icon
                    as={IoLogIn}
                    width={iconSize}
                    height={iconSize}
                    color="inherit"
                  />
                }
              />
            </>
          )}
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
