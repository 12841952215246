/* eslint-disable prettier/prettier */
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Flex,
  HStack,
  Link,
  Spinner,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import validationSchema from "../validations/create-group-validation-schema";
import {
  createAlias,
  updateAlias,
  deleteAlias,
  getAlias,
} from "@defense-station/api-service";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import InputField from "../components/fields/InputField";
import Card from "../components/card/Card";
import toast from "../components/toast";
import CardHeaderText from "../components/custom-styled-components/CardHeaderText";
import CardDescriptionText from "../components/custom-styled-components/CardDescriptionText";
import CustomButton from "../components/custom-styled-components/CustomButton";
import * as yup from "yup";
import CustomFormLabel from "../components/custom-styled-components/CustomFormLabel";
import CustomText from "../components/custom-styled-components/CustomText";
import * as timeago from "timeago.js";
import ConfirmationDialog from "../components/ConfirmationDialog";

const VALIDATION_SCHEMA = yup.object().shape({
  alias: yup
    .string()
    .matches(/^[a-zA-Z]+$/, "Alias should have only letters without spaces.")
    .required(),
});

export default function Alias(props) {
  const { user } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [isDeleting, setDeleting] = useState(false);
  const [data, setData] = useState();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const fetchAlias = useCallback(async () => {
    getAlias({
      account_id: user?.account_id,
      username: user?.username,
    })
      .then((res) => {
        if(res?.data?.iam_v1_GetAlias?.alias){
          setData(res?.data?.iam_v1_GetAlias)
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false)
      })
  }, [user?.account_id, user?.username]);

  const onDeleteAliasClick = () => {
    onOpen();
  } 

  const onDeleteAlias = async () => {
    setDeleting(true)
    onClose();
    deleteAlias({
      account_id: user?.account_id,
      username: user?.username,
      alias: data?.alias
    })
    .then(res => {
      setData(null)
    })
    .catch(e => {
      console.log(e)
      toast({
        status: "error",
        description: e.message
      })
    })
    .finally(() => {
      setDeleting(false)
    })
  }

  useEffect(() => {
    if(user){
      fetchAlias();
    }
  }, [fetchAlias, user])
  return (isLoading?
    <Center h="100vh" w="full">
      <Spinner />
    </Center>
    :
    <Box minH={"100vh"} p="6">
      <Box mb={{ sm: "8px", md: "0px" }}>
        <Breadcrumb>
          <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
            <BreadcrumbLink href="/" color={secondaryText}>
              IAM
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem color={secondaryText} fontSize="sm">
            <BreadcrumbLink href="/iam/groups/list" color={secondaryText}>
              Alias
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        {/* Here we create navbar brand, based on route name */}
        <Link
          color={mainText}
          href="#"
          bg="inherit"
          borderRadius="inherit"
          fontWeight="bold"
          fontSize="34px"
          _hover={{ color: { mainText } }}
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
        >
          Create Alias
        </Link>
      </Box>
      {data?
      <Card>
        <Flex justifyContent={"space-between"} align={"center"}>
        <Flex flexDir={"column"} w="full" gap="10px">
        <HStack align={"center"} spacing={"1"}>
          <CustomFormLabel m="0">Alias:</CustomFormLabel>
          <CustomText>{data?.alias}</CustomText>
        </HStack>
        <HStack align={"center"} spacing={"1"}>
          <CustomFormLabel m="0">Created At:</CustomFormLabel>
          <CustomText>{timeago.format(data?.created_at)}</CustomText>
        </HStack>
        </Flex>
        <CustomButton isLoading={isDeleting} onClick={onDeleteAliasClick} colorScheme="red" >
            Delete
          </CustomButton>
        </Flex>
      </Card>
      :<Formik
        enableReinitialize
        validateOnChange={false}
        validationSchema={VALIDATION_SCHEMA}
        initialValues={{
          alias: "",
        }}
        onSubmit={async (values, { resetForm }) => {
          try {
            const res = await createAlias({
              username: user?.username,
              account_id: user?.account_id,
              alias: values?.alias,
            });
            setData({
              alias: res?.data?.iam_v1_CreateAlias?.alias,
              created_at: new Date()
            })
            resetForm();
            toast({
              title: "Success",
              description: "Alias has been created.",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top",
            });
          } catch (e) {
            if (e.response) {
              toast({
                title: "Response Error",
                description: e?.response?.errors[0]?.message,
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top",
              });

              return;
            }

            toast({
              title: "Application Error",
              description: e.message,
              status: "error",
              duration: 4000,
              isClosable: true,
              position: "top",
            });
          }
        }}
      >
        {({ values, errors, isSubmitting, setFieldValue, handleChange }) => {
          return (
            <>
              <Form>
                <Card mb="4" flex={{ base: "1", lg: "2" }}>
                  <Flex direction="column" mb="30px" ms="10px">
                    <CardHeaderText color={textColorPrimary} fontWeight="bold">
                      Alias
                    </CardHeaderText>
                    <CardDescriptionText color={textColorSecondary}>
                      Create company alias
                    </CardDescriptionText>
                  </Flex>
                  <InputField
                    mb="25px"
                    pr="4.5rem"
                    type={"text"}
                    max={5}
                    label="Alias"
                    required
                    error={errors?.alias}
                    name="alias"
                    value={values?.alias}
                    onChange={handleChange}
                  />
                  <Flex justifyContent={"flex-end"}>
                    <CustomButton
                      isLoading={isSubmitting}
                      colorScheme={"brand"}
                      type="submit"
                    >
                      Create
                    </CustomButton>
                  </Flex>
                </Card>
              </Form>
            </>
          );
        }}
      </Formik>}
      <ConfirmationDialog title={"Delete Alias"} description={"Are you sure? You can't undo this action afterwards."} onOpen={onOpen} onClose={onClose} onConfirm={onDeleteAlias} isOpen={isOpen} />
    </Box>
  );
}
