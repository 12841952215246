import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react'
import React from 'react'
import CustomButton from './custom-styled-components/CustomButton'

export default function ConfirmationDialog({title, description, isOpen, onClose, onConfirm}) {
  const cancelRef = React.useRef()
  return (
    <AlertDialog
    isOpen={isOpen}
    leastDestructiveRef={cancelRef}
    onClose={onClose}
  >
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
          {title}
        </AlertDialogHeader>

        <AlertDialogBody>
          {description}
        </AlertDialogBody>

        <AlertDialogFooter>
          <CustomButton ref={cancelRef} onClick={onClose}>
            Cancel
          </CustomButton>
          <CustomButton colorScheme='red' onClick={onConfirm} ml={3}>
            Confirm
          </CustomButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>
  )
}
