import { Flex, Box } from "@chakra-ui/react";
import * as React from "react";
import Footer from "../components/footer/FooterAdmin";
import MobileTopBar from "../components/MobileTopBar";
import Sidebar from "../components/Sidebar";
import { createBrowserHistory } from "history";
import { useAuth } from "../context/auth";
import PermissionDenied from "../pages/PermissionDenied";

export const MainLayout = ({ children }) => {
  const { hasPermission, setHasPermission } = useAuth();
  const history = createBrowserHistory();
  React.useEffect(() => {
    const unlisten = history.listen(({ location, action }) => {
      setHasPermission(true);
    });
    return () => unlisten();
  }, []);
  return (
    <Flex flexDirection="column">
      <MobileTopBar />
      <Flex flex="1" overflow="hidden">
        <Sidebar
          display={{
            base: "none",
            md: "flex",
          }}
        />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100% - 240px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 240px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Box flex="1" overflow="auto" minH={"100vh"} maxH={"100vh"} pb="30px">
            {children}
          </Box>
          <Box>
            <Footer />
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default MainLayout;
