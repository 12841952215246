import {
    Flex,
    Spacer,
    useColorModeValue as mode,
  } from '@chakra-ui/react';
  import * as React from 'react';
  import SidebarContent from '../components/sidebar/components/Content'
  
  export const Sidebar = (props) => {
    let sidebarBackgroundColor = mode("white", "navy.800");
    return (
      <Flex
    bg={sidebarBackgroundColor}
      direction="column"
      borderRightWidth="1px"
      width="240px"
      {...props}
    >
      <Flex direction="column" flex="1" pt="5" pb="4" overflowY="auto">
        <SidebarContent />
        <Spacer />
      </Flex>
    </Flex>
    );
  };
  
  export default Sidebar;
  